import { render, staticRenderFns } from "./AssignCompany.vue?vue&type=template&id=60f96050"
import script from "./AssignCompany.vue?vue&type=script&lang=js"
export * from "./AssignCompany.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports