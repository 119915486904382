<template>
  <v-dialog v-model="localDialog" max-width="750px">
    <form-wizard
      :form-data="formData"
      :item="item"
      :namespace="namespace"
      :title="title"
      :go-to="goTo"
      :return-previous-screen="returnPreviousScreen"
      @dialog-cancel="updateDialog(false)"
      :icon="icon"
      :store="store"
      :store-action="storeAction"
    ></form-wizard>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    companyId: {
      default: null,
      required: false
    },
    relatedCompanies: {
      default() {
        return [];
      },
      required: false
    },
    goToUrl: {
      default: null,
      required: false
    },
    returnPreviousScreen: {
      default: false,
      required: false
    }
  },
  components: {
    FormWizard: () => import("@/components/core/form/FormWizard")
  },
  computed: {
    ...mapGetters({
      availableCompanies: "companies/hrSuppliers"
    }),
    localDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit("dialog-update", value);
      }
    },
    icon() {
      return "mdi-brifcase-outline";
    },
    storeAction() {
      return "companies/assignCompany";
    },
    store() {
      return "company";
    },
    title() {
      return "Assign related companies";
    },
    namespace() {
      return "company";
    },
    goTo() {
      return this.goToUrl;
    },
    item() {
      return {
        id: this.companyId,
        companies: this.relatedCompanies
      };
    },
    formData() {
      let form = [
        {
          text: "Related companies*",
          value: "companies",
          flex: "col-sm-12 col-md-12",
          required: "required",
          options: this.availableCompanies,
          companyDisplayName: true,
          chips: true,
          multiple: true,
          showOnTop: true,
          class: "",
          validate: "required"
        }
      ];
      return form;
    }
  },
  data() {
    return {};
  },

  async mounted() {
    await this.$store.dispatch("companies/getSuppliers", {
      name: "hrSuppliers",
      company_group: "hr-suppliers"
    });
  },
  created() {},
  methods: {
    updateDialog(dialog) {
      this.localDialog = dialog;
    },
    cancelDialog() {
      this.$emit("dialog-cancel", true);
    }
  }
};
</script>
